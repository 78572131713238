import 'reflect-metadata';
import ReactDOM from 'react-dom';
import 'react-reflex/styles.css';
import './index.css';

import { StrictMode } from 'react';
import { socket, socketContext } from '@powertrader/core';
// import { io } from 'socket.io-client';
import App from './App';
import { DemoSystemNotice } from './DemoSystem';
// import * as serviceWorker from './serviceWorker';

// require('dotenv').config();

const isDemo = window.location.origin === 'https://workshopdemo.powertrader.online';
if (isDemo) {
  document.body.style.backgroundImage = 'linear-gradient(310deg, rgb(29 124 81) 23%, rgb(11 101 55) 73%)';
}

ReactDOM.render(
  <StrictMode>
    <socketContext.Provider value={socket}>
      {isDemo && <DemoSystemNotice />}
      <App />
      {isDemo && <DemoSystemNotice />}
    </socketContext.Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
