import { bilateralTradesContext, gameProgressContext, socket } from '@powertrader/core';
import { message, notification } from 'antd';
import { useContext } from 'react';
import QrReader from 'react-qr-reader';
import styles from './BilateralScreen.module.css';

export const BilateralQRDeleter = () => {
  const bilateralTrades = useContext(bilateralTradesContext);
  const { roundID } = useContext(gameProgressContext);

  const onScan = (incomingCode: string | null) => {
    if (incomingCode) {
      const matchingBilateral = bilateralTrades.find(
        bilateral => bilateral.token === incomingCode && bilateral.status === 'offered' && bilateral.startRoundID === roundID
      );

      if (matchingBilateral) {
        socket.emit('deleteBilateralTrade', matchingBilateral?.id);
        message.success('Token deactivated');
      } else {
        message.error('Token not active');
      }
    }
  };

  return (
    <div className={styles.container} style={{ marginTop: 100 }}>
      <h2>Scan code to deactivate</h2>
      {/* @ts-ignore */}
      <QrReader
        onScan={onScan}
        onError={() => notification.error({ message: 'Could not scan. Check camera permissions or try another token.', duration: 0 })}
        style={{ width: '100%' }}
      />
    </div>
  );
};
