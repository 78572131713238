import { useContext, useEffect, useState } from 'react';
import styles from './BilateralScreen.module.css';
import QrReader from 'react-qr-reader';
import { bilateralTradesContext, gameProgressContext, setupDataContext, socket } from '@powertrader/core';
import { Spin, message, notification } from 'antd';
import { ValidToken } from './ValidToken';
import { NewToken } from './NewToken';
import { BilateralTrade } from '@powertrader/schema';
import { decodeToken } from './decodeToken';

export const BilateralScreen = () => {
  const { user, teams, settings, markets } = useContext(setupDataContext);
  const { roundID } = useContext(gameProgressContext);
  const bilateralTrades = useContext(bilateralTradesContext);
  const [code, setCode] = useState<string | undefined | null>();
  const [scannedBilateral, setScannedBilateral] = useState<BilateralTrade>(); //prevents forcing updates

  const rawBilateral = bilateralTrades.find(
    bilateral => bilateral.token === code && bilateral.status === 'offered' && bilateral.startRoundID === roundID
  );

  const currentTeam = teams.find(team => team.teamID === user.teamID);
  const isOfferingTeam = rawBilateral?.offeringTeam.teamID === currentTeam?.teamID;
  const isReceivingTeam = scannedBilateral && scannedBilateral?.offeringTeam.teamID !== currentTeam?.teamID;
  const isOffer = scannedBilateral?.status === 'offered';

  useEffect(() => {
    const resetScreen = (trade: BilateralTrade) => {
      if (trade.token === code) {
        setCode(null);
        setScannedBilateral(undefined);
      }
    };

    socket.on('rejectBilateralTradeMobile', resetScreen);
    socket.on('acceptBilateralTradeMobile', resetScreen);
    socket.on('deleteBilateralTradeMobile', (trade: BilateralTrade) => {
      if (!isReceivingTeam) {
        resetScreen(trade);
        notification.open({
          message: 'Bilateral Trade Deleted',
          description: 'This trade was manually deleted by the heuristic or offering team',
          duration: 10,
          style: { borderBottom: `10px solid red` }
        });
      }
    });

    return () => {
      socket.off('rejectBilateralTradeMobile');
      socket.off('acceptBilateralTradeMobile');
      socket.off('deleteBilateralTradeMobile');
    };
  }, [code, isReceivingTeam]);

  const onScan = (incomingCode: string | null) => {
    if (incomingCode && !code) {
      const matchingBilateral = bilateralTrades.find(
        bilateral => bilateral.token === incomingCode && bilateral.status === 'offered' && bilateral.startRoundID === roundID
      );

      const decodedToken = decodeToken(incomingCode, setCode);

      const market = markets.find(mkt => mkt.marketID === decodedToken?.marketID);
      if (
        (market?.type === 'carbonMarket' && !settings.allowCarbonTrading) ||
        (market?.type === 'hydrogenMarket' && !settings.allowHydrogenTrading) ||
        (market?.type === 'powerExchange' && !settings.allowBilaterals && !settings.allowHedging)
      ) {
        message.warning('This market is not activated');
        return;
      }

      setCode(incomingCode);

      if (matchingBilateral) {
        setScannedBilateral(matchingBilateral);
      }
    }
  };

  if (!currentTeam) return <Spin size='large' />;
  return (
    <div className={styles.container}>
      <p>{user.userName} trading for</p>
      <h1 className={styles.teamLabel} style={{ backgroundColor: currentTeam?.color }}>
        Team {currentTeam?.label}
      </h1>
      <h2>Bilateral Trading</h2>

      {scannedBilateral && isOffer && !isOfferingTeam ? (
        <ValidToken setCode={setCode} rawBilateral={rawBilateral} scannedBilateral={scannedBilateral} setScannedBilateral={setScannedBilateral} />
      ) : code ? (
        <NewToken code={code} setCode={setCode} isActive={rawBilateral?.status === 'offered'} setScannedBilateral={setScannedBilateral} />
      ) : null}

      <div style={{ display: code ? 'none' : 'unset' }}>
        {/* @ts-ignore */}
        <QrReader
          onScan={onScan}
          onError={() => notification.error({ message: 'Could not scan. Check camera permissions or try another token.', duration: 0 })}
          style={{ width: '100%' }}
        />
        <h3>Scan a token to begin</h3>
      </div>
    </div>
  );
};
