import { useCallback, useContext, useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { IErrorProps, IUser, IUserRequest, BaseTeam } from '@powertrader/schema';
import { setErrorContext, socket } from '@powertrader/core';
import styles from './LoginArea.module.css';
import { isNumber } from 'lodash';
import { isDesktop } from 'react-device-detect';

interface ILoginArea {
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setControlPannel: React.Dispatch<React.SetStateAction<boolean | null>>;
  requiresLogin: boolean;
  isDemoSite: boolean;
}
export const LoginArea = ({ setUser, setIsLoggedIn, setControlPannel, requiresLogin, isDemoSite }: ILoginArea) => {
  const setError = useContext(setErrorContext);
  const [teams, setTeams] = useState<BaseTeam[]>([]);
  const [allowPlayerLogin, setAllowPlayerLogin] = useState<boolean>();
  const getQueryParam = (param: string) => new URLSearchParams(window.location.search).get(param);
  const [formData, setFormData] = useState<{
    userName: string;
    team: number | undefined;
    passcode: string | null | number;
  }>({
    userName: getQueryParam('name') || '',
    team: Number(getQueryParam('teamID')) || undefined,
    passcode: getQueryParam('passcode') || null
  });
  const [userAlert, setUserAlert] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    socket.emit('teamsLoginListRequest', (error: IErrorProps['error'], data: BaseTeam[], isApproved: boolean) => {
      if (error) {
        setError({ message: 'cannot get userID', error });
      } else {
        setAllowPlayerLogin(isApproved);
        setTeams(data);
      }
    });

    return () => {
      socket.off('setupData');
    };
  }, [setError]);

  const submitToServer = useCallback(
    (data: { userName: string; passcode: string | null | number; team: number | undefined }) => {
      const { userName, passcode, team } = data;
      try {
        setSubmitting(true);

        const userJoiningTeam = teams.find(t => team === Number(t.teamID));
        if (userJoiningTeam) {
          const userRequest: IUserRequest = {
            userName: userName,
            teamID: userJoiningTeam.teamID,
            role: userJoiningTeam.type,
            teamType: userJoiningTeam.type,
            passcode: Number(passcode) * 3
          };
          socket.emit('newUser', userRequest, (error: IErrorProps['error'], insertedID: number | boolean) => {
            if (error) {
              setError({ message: 'cannot get userID', error });
            } else if (isNumber(insertedID) && insertedID > 0) {
              const newUser: IUser = {
                userID: Number(insertedID),
                userName: userName,
                role: userJoiningTeam.type,
                teamType: userJoiningTeam.type,
                teamID: userJoiningTeam.teamID,
                color: userJoiningTeam.color
              };
              setUser(newUser);
              sessionStorage.setItem('user', JSON.stringify(newUser));
              window.history.pushState({}, document.title, window.location.pathname);
              socket.emit('eventLog', {
                type: 'login',
                userID: insertedID,
                log: `${userName} (${teams.find(t => team === t.teamID)?.label}) has logged in`
              });
              if (userJoiningTeam.type === 'ADMIN') {
                setControlPannel(true);
              } else {
                setControlPannel(false);
              }
              setIsLoggedIn(true);
            } else {
              socket.emit('eventLog', {
                type: 'login',
                log: `${userName} used an incorrect passcode`
              });
              setFormData({ userName, team, passcode: '' });
              setSubmitting(false);
              setUserAlert('Incorrect Passcode');
              setTimeout(() => {
                setUserAlert('');
              }, 5000);
            }
          });
        } else {
          setError({
            message: `Error at Login - Cannot find selected team (${userName} - ${team} - ${passcode})`
          });
        }
      } catch (error) {
        setError({
          message: `Error at Login - ${userName} - ${team} - ${passcode}`,
          //@ts-ignore
          error
        });
        setSubmitting(false);
        message.error('Error logging in, please reload try again');
      }
    },
    [setControlPannel, setError, setIsLoggedIn, setUser, teams]
  );
  const submit = () => {
    // if (/\s/.test(formData.userName) && `${formData.passcode}`.length === 6) {
    //   submitToServer({ ...formData, team: -1 });
    //   return;
    // }
    if (!formData.userName) {
      setUserAlert('Please enter your first and second name');
      setTimeout(() => {
        setUserAlert('');
      }, 5000);
      return;
    }
    if (!formData.team) {
      setUserAlert('Please select a team');
      setTimeout(() => {
        setUserAlert('');
      }, 5000);
      return;
    }
    if (!formData.passcode) {
      setUserAlert('Please enter a passcode');
      setTimeout(() => {
        setUserAlert('');
      }, 5000);
      return;
    }

    submitToServer(formData);
  };

  if (getQueryParam('login') === 'true') submit();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img className={styles.logo} alt='Heuristic Logo' src='/logo.png' />
        <h2>
          {!isDemoSite && 'Welcome to'} Power Trader {isDemoSite && 'Demo Site'}
        </h2>

        {!requiresLogin || submitting ? (
          <div className={styles.requiresLogin}>
            {!requiresLogin ? <h2>Loading</h2> : <h2>Logging in</h2>}
            <Spin size='large' />
          </div>
        ) : !allowPlayerLogin && formData?.team && formData.team > 0 ? (
          <div className={styles.loginForm}>
            <h3 style={{ textAlign: 'center' }}>The game is being setup, please use the link below once instructed to begin.</h3>
            <button
              className={styles.submitButton}
              type='button'
              onClick={event => {
                event.preventDefault();
                window.location.reload();
              }}>
              Begin
            </button>
          </div>
        ) : (
          <>
            <form
              data-testid='login-form'
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  submit();
                }
              }}
              className={styles.loginForm}>
              <label>Full Name</label>
              <input
                data-testid='name'
                autoComplete='off'
                className={styles.inputBox}
                value={formData.userName}
                onChange={event => setFormData({ ...formData, userName: event.target.value })}
              />
              <label>Team</label>
              <select
                data-testid='select'
                className={styles.inputBox}
                name='teamName'
                value={formData.team || undefined}
                onChange={event => setFormData({ ...formData, team: Number(event.target.value) })}>
                <option value='' selected disabled hidden>
                  Choose here
                </option>
                {teams.map(team => (
                  <option key={team.teamID} value={team.teamID}>
                    {team.label}
                  </option>
                ))}
              </select>
              <label>Team Passcode</label>
              <input
                data-testid='passcode'
                type='password'
                autoComplete='off'
                style={{ fontSize: isDesktop ? '35px' : '16px' }}
                className={styles.inputBox}
                value={formData.passcode || undefined}
                onChange={event => {
                  setFormData({
                    ...formData,
                    passcode: event.target.value
                  });
                }}
              />
              <button
                data-testid='submit'
                className={styles.submitButton}
                type='submit'
                onClick={event => {
                  event.preventDefault();
                  submit();
                }}>
                Let's Go!{' '}
              </button>
              <p className={styles.formError}>{userAlert !== '' && userAlert}</p>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
